const en = {
  press: "PRESS",
  rules: "RULES",
  submission: "SUBMISSION",
  about: "ABOUT",
  contact: "CONTACT",
  eventNews: "Event News",
  awardCollection: "Award Collection",
  news: "NEWs",
  award: "Award",
  text1:
    "2021 Zhejiang Yunhe Wooden Toy Creative Design Competition Call For Entries Globally",
  text2: "2018 Wooden Toy Design Workshop｜Excellent Award",
  text3: "2021 Zhejiang Yunhe Wooden Toy Creative Design Competition",
  text4: "2018 Wooden Toy Design Workshop｜Excellent Award",
  text5: "YUNHE WOODEN TOY INDUSTRY INNOVATION RESEARCH INSTITUTE",
  text6:
    "Zhejiang Yunhe Wooden Toy Creative Design Competition, established in Yunhe, 2015. By holding the competition, it can promote the design innovation of Yunhe wooden toy products, attract design talent, promote the efficient and sustainable development of the wooden toy industry, and finally create a 'global wooden toy industry'.",
  phone: "Phone",
  email: "Email",
  address: "Address: No. 16, Renmin Road, Yunhe, Zhejiang, China",
  weChatOfficialAccount: "WeChat Official Account",
  welcomeLogin: "Welcome, Please Login",
  login: "login",
  register: "register",
  forgetPassword: "Forget the Password",
  newRegister: "No account, new account registration",
  emailPlaceholder: "please input email",
  passwordPlaceholder: "please input password",
  confirmPasswordPlaceholder: "please input confirm password",
  registerNewUser: "Register new users",
  useAccount: "Login with an existing account",
  companyName: "ZHEJIANG YUNHE WOODEN TOY CREATIVE DESIGN COMPETITION",
  cansaixuzhi_title: "ENTRY RULES",
  cansaixuzhi:
    "Participants should fully understand the content of the “2022 (6th) Zhejiang Yunhe Wooden Toy Creative Design Competition“, please read the following instructions and confirm that they will observe the following Regulations during this competition",
  cansaixuzhi_1:
    "1. Entries must be original works of the contestants (not to infringe other people's intellectual property rights or any other rights).",
  cansaixuzhi_2:
    "2. The intellectual property rights of the awarded winning works of the competition belong to the competition organizing committee. The Committee has the right to apply for the patent of the winning works before the works are exhibited. The author enjoys the co-authorship of the patent.",
  cansaixuzhi_3:
    "3. The Organizing Committee has the right to display, publish and publicize all entries. Without the permission of the Organizing Committee of the competition, any other unit or individual shall not publicize, publish or display the design scheme of the winning products of this competition.",
  cansaixuzhi_4:
    "4.The legal liability caused by the plagiarism of works, the theft of trade secrets, etc. of the contestant shall be borne by the contestant, and the organizer has the right to disqualify the contestant.",
  cansaixuzhi_5:
    "5. The competition organizing committee reserves the right of final interpretation of this competition.",
  cansaixuzhi_6:
    "",
  tougaozuopin: "Contributed works",
  productName: "Name",
  teamInfo: "Team Info",
  teacherInfo: "Teacher Info",
  username: "Name",
  userphone: "Phone",
  institutions: "Institutions/Units",
  addTeam: "Add Team Members",
  entryDisplay: "Entry Display",
  thumbnail: "Thumbnail",
  imageRequest:
    "JPG format, less than 5M, 420mm*594mm, vertical version, 300dpi. RGB color",
  uploadImage: "Upload Image File",
  entryDescription: "Entry Description",
  englishWords: "English (within 100 words)",
  agreenment:
    "I have read the 2021 event announcement and ensure that the above information is true and accurate.",
  save: "SAVE",
  uploadlimit: "Only jppg/jpeg files can be uploaded and not exceeding 5M",
  thumbnailLimit: "Only jppg/jpeg files can be uploaded and not exceeding 300K",
  uploadDesc: 'In case the upload speed is slow or unable to upload, please contact',
  layout1:
    "Layout documents are required for jpg format, specificationS A2 (420mm x 594mm), vertical version, resolution 300dpi, a work limited to one layout;",
  layout2: `The content of the layout shall include the competition logo, work name, overall effect drawing, local detail drawing, basic appearance size, more than 2 main views and design description. The author's name and any mark shall not appear in the layout, otherwise it shall be deemed as invalid work.`,
};

export default en;
