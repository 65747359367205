export default {
  login: "/userLogin",
  register: "/user/add",
  updateUserInfo: "/user/update",
  userInfo: "/user/info/",
  homePageList: "/banner/getBannerInfo",
  newsPageList: "/newInfo/getPageList",
  newsDetail: "/newInfo/info/",
  newsWorkDetail: "/preWork/info/",
  rule: "/playRule/info/",
  addWorks: "/works/add",
  upload: "/upload",
  address: "/area/info/",
  worksList: "/works/getPageList",
  updateWork: "/works/update",
  deleteWork: "/works/delete/",
  workInfo: "/works/info/",
};
