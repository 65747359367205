import Vue from "vue";
import App from "./App.vue";
import "./plugins/element.js";
// import "element-ui/lib/theme-chalk/index.css";
import {
  Button,
  Image,
  Tabs,
  TabPane,
  Form,
  FormItem,
  Input,
  Select,
  Option,
  MessageBox,
  Message,
  Upload,
  Checkbox,
  Popover,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Dialog,
} from "element-ui";
import i18n from "./i18n/i18n";
import store from "./store";
import router from "./router";

Vue.config.productionTip = false;
Vue.use(Button);
Vue.use(Image);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Select);
Vue.use(Option);
Vue.use(Upload);
Vue.use(Checkbox);
Vue.use(Popover);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Dialog);

Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$message = Message;

document.onmousedown = (event) => {
  if (event.button === 2) {
    alert("禁止使用右键");
  }
};

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
