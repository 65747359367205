import { Language } from "../utils/constants";

export default {
  changeLanguage(state) {
    state.isChinese = !state.isChinese;
    state.lang = state.isChinese ? Language.CN : Language.EN;
  },

  getBannerList(state, bannerList) {
    state.bannerList = bannerList;
  },
};
