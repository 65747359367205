import axios from "axios";
import router from "@/router";
import { Message } from "element-ui";
// import QS from "qs";

if (process.env.NODE_ENV == "production") {
  // axios.defaults.baseURL = "https://springboot.plus/api";
  axios.defaults.baseURL = "http://api.yhmwdesign.com/api";
} else {
  // axios.defaults.baseURL = "http://47.98.208.224:8888/api";
  axios.defaults.baseURL = "http://api.yhmwdesign.com/api";
}

axios.defaults.headers = {
  post: {
    "Content-Type": "application/json",
  },
};

axios.interceptors.request.use((config) => {
  config.headers.common = {
    token: localStorage.getItem("token") || "",
  };
  return config;
});

// 响应拦截器
axios.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      if (response.data.code === 200) {
        return Promise.resolve(response);
      } else {
        Message.error(response.data.message);
      }
    } else {
      return Promise.reject(response);
    }
  },
  // 服务器状态码不是200的情况
  (error) => {
    if (error.response.status) {
      switch (error.response.status) {
        // 401: 未登录
        // 未登录则跳转登录页面，并携带当前页面的路径
        // 在登录成功后返回当前页面，这一步需要在登录页操作。
        case 401:
          Message.error("用户未登陆，请先登陆");
          router.replace({
            path: "/login",
          });
          break;
        // 404请求不存在
        case 404:
          Message.error("网络请求不存在");
          // Toast({
          //   message: "网络请求不存在",
          //   duration: 1500,
          //   forbidClick: true,
          // });
          break;
        // 其他错误，直接抛出错误提示
        default:
          Message.error("出现异常");
      }
      return Promise.reject(error.response);
    }
  }
);

class ApiService {
  constructor() {}

  get(url, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          params: params,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.data);
        });
    });
  }

  post(url, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, params)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.data);
        });
    });
  }
}

export default new ApiService();
