import Vue from "vue";
import Router from "vue-router";
const login = () => import("@/pages/login");
const Layout = () => import("@/pages/layout");
const newsDetail = () => import("@/pages/news/news-info");
const newsWorksDetail = () => import("@/pages/news/previous-works");
const Home = () => import("@/pages/home");
const News = () => import("@/pages/news");
const Rules = () => import("@/pages/rules");
const Submission = () => import("@/pages/submission");
const UserCenter = () => import("@/pages/user-center");
const MobileHome = () => import("@/pages/mobile/home");

Vue.use(Router);

let isPc = true;

if (
  navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )
) {
  isPc = false;
  console.log("移动端");
} else {
  isPc = true;
  console.log("pc端");
}

const mobileRoute = [
  { path: "", redirect: "home", name: "home" },
  { path: "/mobile", component: MobileHome, name: "mobileHome" },
  { path: "/home", component: MobileHome, name: "mobileHome" },
];

const pcRoute = [
  {
    path: "",
    component: Layout,
    children: [
      { path: "", redirect: "home", name: "home" },
      { path: "home", component: Home, name: "home" },
      { path: "news", component: News, name: "news" },
      { path: "news/:id", component: newsDetail, name: "newsDetail" },
      {
        path: "news/works/:id",
        component: newsWorksDetail,
        name: "newsWorksDetail",
      },
      { path: "rules", component: Rules, name: "rules" },
      { path: "submission", component: Submission, name: "submission" },
      { path: "user/center", component: UserCenter, name: "usercenter" },
    ],
  },
];

const dynamicRoute = isPc ? pcRoute : mobileRoute;

const router = new Router({
  // mode: "history",
  routes: [
    { path: "/login", component: login },
    ...dynamicRoute,
    // { path: "*", redirect: "/home" },
  ],
});

export default router;
