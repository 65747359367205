import Vue from "vue";
import Vuex from "vuex";
import actions from "./action";
import mutations from "./mutations";
import { Language } from "../utils/constants";

Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    isChinese: true,
    lang: Language.CN,
    bannerList: [],
  },
  actions,
  mutations,
});

export default store;
