const cn = {
  press: "赛事资讯",
  rules: "赛事规则",
  submission: "报名入口",
  about: "关于比赛",
  contact: "联系我们",
  eventNews: "新闻资讯",
  awardCollection: "历届作品",
  news: "最新资讯",
  award: "获奖作品",
  text1: "2021第五届浙江云和木玩创意设计大赛全球启动征集",
  text2: "2018木玩创意设计营｜优秀作品",
  text3: "2021第五届浙江云和木玩创意设计大赛",
  text4: "2018木玩创意设计营｜优秀作品",
  text5: "云和木玩产业创新研究院",
  text6:
    "浙江云和木玩创意设计大赛自2017年创办以来，在各主办单位和协作单位的支持下已成功举办四届。随着大赛知名度和影响力的不断提高，为木玩产业征集了一批优秀的创意作品，提高了木玩产业创新设计的热情。为进一步推动云和木制玩具产品设计创新，树立云和木玩在国内外的品牌形象，挖掘木玩设计开发人才，有效促进木制玩具产业高效、持续发展，为打造“全球木玩创制中心”注入新动能，决定举办2021第五届浙江云和木玩创意设计大赛。木制玩具的种类和功能都非常丰富，仅云和县生产的木制玩具就有几万种。木制玩具按照使用年龄，可分为婴儿期、幼儿期、少年期、成年人、老年人玩具。其中幼儿期的木制玩具，主要有拖拉玩具、拼图积木玩具、计数玩具、看图识字玩具、工具玩具、场景玩具；少年期的木制玩具，以提高智力的益智玩具为主，如拼装类的七巧板、华容道、魔方等；老年人的木制玩具，主要是健身玩具、益智玩具、观赏玩具。木制玩具按照功能，又可分为益智类、摆设类、挂饰类、幼教类…一件木玩产品往往具有多种功能。  本届大赛以“玩木益智”为主题，要求围绕不同群体的个性化需求设计，有实用的功能性、良好的可玩性、形式多样的益智功能，外观设计美观，色彩搭配合理。符合木制玩具生产工艺流程和规则，具有量产可能性，易于被市场接受。",
  phone: "电话",
  email: "邮箱",
  address: "地址：浙江省云和县浮云街道人民路16号",
  weChatOfficialAccount: "公众号",
  welcomeLogin: "欢迎您！请登录",
  login: "登 陆",
  register: "注 册",
  forgetPassword: "忘记密码",
  newRegister: "没有账号，新账号注册",
  emailPlaceholder: "请输入邮箱",
  passwordPlaceholder: "请输入密码",
  confirmPasswordPlaceholder: "请输入确认密码",
  registerNewUser: "注册新用户",
  useAccount: "使用已有账号登录",
  companyName: "浙江云和木玩创意设计大赛",
  cansaixuzhi_title: "参赛须知",
  cansaixuzhi:
    "参赛者在充分了解《2022年(第六届)浙江云和木玩创意设计大赛》全部内容的前提下，请详细阅读以下参赛须知，并确认在本次大赛期间遵守以下各项规定：",
  cansaixuzhi_1:
    "1、参赛作品必须为参赛者原创作品(不得侵犯他人的知识产权或其它任何权利)，并且专为此次赛事创作(一稿多投者如被发现将取消获奖资格)。",
  cansaixuzhi_2:
    "2、大赛金、银、铜及优秀奖作品的知识产权归大赛组委会所有。大赛组委会有权在作品展出前进行获奖作品的专利申报。设计作者享有专利的共同署名权。",
  cansaixuzhi_3:
    "3、大赛组委会对所有参赛作品拥有展示、出版和宣传的权利。未经大赛组委会许可其他任何单位和个人不得将本次大赛获奖产品的设计方案进行宣传、出版、展示等。",
  cansaixuzhi_4:
    "4、如因参赛者的剽窃作品、窃取商业秘密等行为所引起的法律责任由参赛者自负，并且主办单位有权取消其参赛资格。",
  cansaixuzhi_5:
    "5、如有违反以上条款且造成严重后果，大赛组委会保留追究相应责任人法律责任的权利。",
  cansaixuzhi_6: "6、6.大赛组委会对本届大赛保留最终解释权。",
  tougaozuopin: "投稿作品",
  productName: "作品名称",
  teamInfo: "团队成员",
  teacherInfo: "指导教师",
  username: "姓名",
  userphone: "联系方式",
  institutions: "院校/单位",
  addTeam: "增加团队成员",
  entryDisplay: "作品提交",
  thumbnail: "缩略图",
  imageRequest: "版面文件格式要",
  uploadImage: "选择文件",
  entryDescription: "设计说明",
  englishWords: "100字以内设计说明",
  agreenment: "我已阅读2021赛事公告，并确保以上信息真实准确",
  save: "保存",
  uploadlimit: "只能上传jpg/jpeg文件，且不超过5M",
  thumbnailLimit: "只能上传jpg/jpeg文件，且不超过300K",
  uploadDesc: "",
  layout1:
    "版面文件要求为jpg格式，规格为A2（420mm x 594mm），竖版，分辨率300dpi，一件作品仅限一个版面；",
  layout2:
    "版面内容应包含大赛标识、作品名称、整体效果图、局部细节图、基本外观尺寸、2个以上主要视图、设计说明，版面中不得出现作者姓名及任何标记，否则视为无效作品。",
};

export default cn;
