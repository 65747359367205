import ApiService from "@/api/apiService";
import API from "@/api";
import { LanguageEnum, Language } from "@/utils/constants";

export default {
  changeLanguage(context) {
    context.commit("changeLanguage");
  },

  async getHomeImg({ commit, state }) {
    const result = await ApiService.post(API.bannerList, {
      language: LanguageEnum[state.isChinese ? Language.CN : Language.EN],
    });
    const bannerList = result.data.records;
    commit("getBannerList", bannerList);
  },
};
