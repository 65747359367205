<template>
  <div id="app">
    <!-- <keep-alive> -->
    <router-view class="view" :key="$route.path"> </router-view>
    <!-- </keep-alive> -->
  </div>
</template>

<script>
export default {
  name: 'app',
  components: {},
  data() {
    return {};
  },
  methods: {}
};
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 1.4vw;
}
body {
  margin: 0;
}
</style>
